import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Select } from "antd";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";

const CreateUser = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();
  //   API
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [conPass, setConPass] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("sub_user");
  const [permission, setPermission] = useState("[0,0]");

  //   Error handlers
  const [Error, setError] = useState(false);

  //   Loaders
  const [Loading, setLoading] = useState(false);

  const handleChangeName = (e) => {
    const value = e.target.value;
    // Use a regular expression to test if the input contains digits
    if (!/\d/.test(value)) {
      // If no digits are found, update the input value
      setFullName(value);
      setError("");
    } else {
      setError("numbers not allowed in name field");
    }
  };

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const CreateUser = () => {
    setError("");
    if (fullName === "") {
      setError("User's name is required");
    } else if (email === "") {
      setError("email is required");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError("Invalid Email");
    } else if (mobile === "") {
      setError("mobile is required");
    } else if (mobile.length > 11 || mobile.length < 8) {
      setError("mobile number must be between 8 to 11 digits only.");
    } else if (pass.length < 6) {
      setError("Pass must be at least 6 characters");
    } else if (pass !== conPass) {
      setError("Password doesn't match");
    } else if (role === "") {
      setError("select user role");
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        fullname: fullName,
        email: email,
        password: pass,
        confirmPassword: conPass,
        mobileNumber: mobile,
        role: role,
        permissions: permission,
        clientId: accessToken?.client?.id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          "users/create-new-user",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.errors) {
            setError(result.errors[0]?.msg);
            setLoading(false);
          } else if (result.message === "User creation limit exceeded") {
            swal("Failed", "User creation limit exceeded", "error");
            setLoading(false);
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            swal(
              "Created!",
              `${fullName.replace("-", " ")}'s account has been created`,
              "success"
            ).then(() => {
              setLoading(false);
              Navigate("/users");
            });
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChange = (value) => {
    setRole(value);
    if (value === "client_admin") setPermission("[1,1]");
  };

  return (
    <div className="flex justify-start md:justify-center animatedView">
      <div className="w-full md:w-1/2">
        <div className="flex ml-14">
          <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 ml-5 z-[999] text-xl">
            Create User
          </h1>
        </div>
        <div className="bg-white p-3 rounded-[20px] flex items-center relative -mt-12">
          <div className="mx-5 w-full flex flex-col gap-3 animatedView">
            {/* Full Name */}
            <div className="relative mt-5">
              <label htmlFor="document" className="font-semibold">
                Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border rounded-lg p-2 w-full`}
                placeholder="enter customer's name"
                value={fullName}
                onChange={handleChangeName}
              />
            </div>
            {/* Email */}
            <div className="relative mt-2">
              <label htmlFor="document" className="font-semibold">
                Email: <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                className={`border rounded-lg p-2 w-full`}
                placeholder="enter customer's email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* Mobile */}
            <div className="relative mt-2">
              <label htmlFor="document" className="font-semibold">
                Mobile Number: <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                className={`border rounded-lg p-2 w-full`}
                placeholder="enter customer's mobile number..."
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            {/* Pass */}
            <div className="relative mt-2">
              <label htmlFor="document" className="font-semibold">
                Password: <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                className={`border rounded-lg p-2 w-full`}
                placeholder="enter password..."
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            {/* ConPass */}
            <div className="relative mt-2">
              <label htmlFor="document" className="font-semibold">
                Confirm Password: <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                className={`border rounded-lg p-2 w-full`}
                placeholder="confirm your password..."
                value={conPass}
                onChange={(e) => setConPass(e.target.value)}
              />
            </div>
            {/* Role */}
            <div className="relative mt-2">
              <ConfigProvider
                theme={{
                  token: {
                    controlHeight: 36,
                    borderRadius: "0px 6px 6px 0px",
                    colorBorder: "rgba(255,255,255,0)",
                    colorPrimaryHover: "#0aa1dd",
                    lineWidth: 0,
                    fontSize: 12,
                  },
                }}
              >
                <label htmlFor="document" className="font-semibold">
                  Role: <span className="text-red-500">*</span>
                </label>
                <Select
                  defaultValue={role}
                  className="w-full border rounded-lg"
                  placeholder="select role"
                  onChange={handleChange}
                  options={[
                    {
                      value: "client_admin",
                      label: "Client Admin",
                    },
                    {
                      value: "sub_user",
                      label: "Sub User",
                    },
                  ]}
                />
              </ConfigProvider>
            </div>
            {Error && (
              <div className="text-xs m-1 text-red-500 animatedView">
                {Error}
              </div>
            )}
            {/* Button */}
            <div className="flex justify-end mt-2">
              <div className="flex justify-between gap-3">
                <button
                  className="bg-gray-400 p-2.5 rounded-md text-white px-3 flex items-center gap-3"
                  onClick={() => Navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  className="p-2.5 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                  onClick={CreateUser}
                >
                  {Loading ? (
                    <Spinner animation="border" variant="light"></Spinner>
                  ) : (
                    <>
                      Create User
                      <span>
                        <i className="bi bi-check2-all"></i>
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
