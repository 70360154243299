import {
  DatePicker,
  Select,
  Space,
  Divider,
  ConfigProvider,
  TimePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";
import Number from "../../../../jsxClient/components/NumberCounter/Number";
import dayjs from "dayjs";
import { RxCross1 } from "react-icons/rx";
import { LocalStorageContext } from "../../../../context/WalletProvider";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";

const CreateInvoice = () => {
  let navigate = useNavigate();
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  const prefilledData = JSON.parse(localStorage.getItem("preferences"));
  let prefillExpiryDate = "";
  const { ExpiryTime } = useContext(LocalStorageContext);
  const defaultInvoiceExpiryDuration = ExpiryTime;

  // Get expiry duration in milliseconds
  const expiryDurationInMillis = ExpiryTime;

  // Convert milliseconds to moment duration
  const duration = moment.duration(expiryDurationInMillis);

  if (duration.isValid()) {
    // Calculate expiry date
    const today = moment();
    const expiryDate = today.add(duration);

    // Format expiry date as desired
    prefillExpiryDate = expiryDate.format("YYYY-MM-DD");
  } else {
    console.error("Invalid expiry duration:", duration);
  }

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  // ID
  const [ID, setID] = useState("");
  // ID Main
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [SMSVia, setSMSVia] = useState("Email");
  const [InvoiceValue, setInvoiceValue] = useState("KWD");
  const [InvoiceRef, setInvoiceRef] = useState("");
  const [InvoiceAmount, setInvoiceAmount] = useState("");
  const [CustomerRef, setCustomerRef] = useState("");
  const [Comment, setComment] = useState("");
  const [TermsCondition, setTermsCondition] = useState(
    prefilledData === null
      ? accessToken?.client?.preferences?.termsAndConditions
      : prefilledData?.termsAndConditions
  );
  const [TermsConditionSect, setTermsConditionSect] = useState(false);
  let defaultDate = new Date(
    Date.now() + parseInt(defaultInvoiceExpiryDuration)
  );
  const [ExpireDate, setExpireDate] = useState(defaultDate);
  const defaultTime = new Date();
  // console.log();
  const [ExpireTime, setExpireTime] = useState(defaultTime);
  // moment(prefillExpiryDate).format("DD-MM-YYYY")
  const [multipleItems, setMultipleItems] = useState(false);

  const handleTimeChange = (time, timeString, DateChnage) => {
    console.log(timeString);
    // if (DateChnage === 0) {
    setExpireTime(timeString);
    // } else setExpireTime(moment().format("hh:mm a"));
  };

  useEffect(() => {
    setCustomerRef(Name && Name?.slice(0, 3) + "/" + MobileNo?.slice(0, 3));
  }, [MobileNo, Name]);

  const [files, setFiles] = useState([]);
  const handleImageChange = (event) => {
    setFiles([...event.target.files]);
  };
  // Loaders
  const [SearchLoader, setSearchLoader] = useState(false);
  const [Loader, setLoader] = useState(false);

  // Error handlers
  const [NotFound, setNotFound] = useState(false);
  const [InputError, setInputError] = useState("");

  // console.log(ExpireDate);

  useEffect(() => {
    const getCustomerData = () => {
      setNotFound(false);
      if (MobileNo.length >= 8) {
        setSearchLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL}customers?q=${MobileNo}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log("API Result:", result);
            if (result.customers.length > 0) {
              const customer = result.customers[0];
              setID(customer.id);
              setName(customer.name);
              setEmail(customer.email);
              setMobileNo(customer.mobileNumber);
              setSearchLoader(false);
            } else if (
              result.message === "unauthorized" ||
              result.message === "token expire"
            ) {
              swal({
                title: "Session Expired!",
                text: `your session has expired! Kindly login again!`,
                icon: "warning",
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  onLogout();
                } else {
                  onLogout();
                }
              });
            } else {
              setSearchLoader(false);
              setNotFound(true);
              setID("");
              setCusName("");
              setCusEmail("");
              setCusMobile("");
            }
          })
          .catch((error) => {
            console.log("Fetch Error:", error);
            setSearchLoader(false);
          });
      }
    };
    getCustomerData();
  }, [MobileNo, accessToken.accessToken]);

  const handleSelectChange = (value) => {
    // console.log("Selected Value:", value); // Log the selected value for debugging
    setMobileNo(value);
  };

  const [DateChnage, setDateChnage] = useState(0);
  const handleExpireChange = (date, dateString) => {
    setExpireDate(dateString);
    setDateChnage(1);
  };

  const [invoiceItems, setInvoiceItems] = useState([
    { details: "", amount: 0, quantity: 0 },
  ]);

  const handleAddItem = () => {
    setInvoiceItems([...invoiceItems, { details: "", amount: 0, quantity: 0 }]);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = invoiceItems.map((item, i) => {
      if (i === index) {
        let newValue = value;

        if (name === "amount") {
          // Restrict to up to 3 decimal places
          newValue = value.match(/^\d*(\.\d{0,3})?$/) ? value : item.amount;
        }

        // Allow clearing the input
        if (name === "amount" || name === "quantity") {
          newValue = value === "" ? "" : parseFloat(newValue) || 0;
        }

        return { ...item, [name]: newValue };
      }
      return item;
    });

    setInvoiceItems(updatedItems);
  };

  const subtotal = invoiceItems.map((item) => item.amount * item.quantity);

  // Calculate total subtotal
  const totalSubtotal = subtotal.reduce((acc, curr) => acc + curr, 0);

  const handleDeleteItem = (index) => {
    const updatedItems = invoiceItems.filter((item, i) => i !== index);
    setInvoiceItems(updatedItems);
  };

  const [AmountError, setAmountError] = useState("");

  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Regular expression to allow only integers and decimals up to 3 places
    const regex = /^\d+(\.\d{0,3})?$/;

    if (regex.test(value) || value === "") {
      // Valid input: update the state with the new value
      setInvoiceAmount(value);
      setAmountError(""); // Clear any previous error
    } else {
      // Invalid input: show an error message immediately
      setAmountError(
        "Invalid Amount, amount must be a number with only up to 3 decimal digits."
      );
    }
  };

  const handleCreateInvoice = () => {
    setInputError("");
    const regex = /^0(\.0+)?$/;
    if (
      Name === "" ||
      MobileNo === "" ||
      ExpireTime === undefined ||
      ExpireDate === "" ||
      (multipleItems === false && InvoiceAmount === "") ||
      (multipleItems === true && invoiceItems[0].details === "") ||
      invoiceItems[0].amount === "" ||
      invoiceItems[0].currency === ""
    ) {
      // setInputError("please fill all the required fields");
      swal("Failed!", "Please fill all the required fields", "error");
    } else if (
      TermsConditionSect === true &&
      (TermsCondition === "" ||
        TermsCondition === null ||
        TermsCondition === undefined)
    ) {
      swal("Failed!", "Please fill terms and condition", "error");
    } else if (
      multipleItems === false &&
      (parseFloat(InvoiceAmount) === 0 ||
        InvoiceAmount === "0.000" ||
        InvoiceAmount === "0.00" ||
        InvoiceAmount === "0.0" ||
        InvoiceAmount === "0")
    ) {
      swal("Error!", `Invoice amount cannot be ${totalSubtotal}`, "error");
    } else {
      const invalidItems = invoiceItems.some(
        (item) => item.amount === 0 || item.quantity === 0
      );

      if (multipleItems === true && invalidItems) {
        // Show Swal error if any invalid item is found
        swal("Error", "Item quantity or amount should not be 0", "error");
      } else {
        setLoader(true);

        const filteredInvoiceItems = invoiceItems.filter(
          (item) => item.amount !== 0 || item.quantity !== 0
        );
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

        const formdata = new FormData();
        multipleItems === false
          ? formdata.append("amount", InvoiceAmount)
          : formdata.append("amount", totalSubtotal);
        formdata.append("invoiceRef", InvoiceRef);
        formdata.append(
          "expiryDate",
          DateChnage === 0
            ? dayjs(ExpireDate).format("YYYY-MM-DD") +
                ", " +
                moment(ExpireTime).format("hh:mm a")
            : ExpireDate + ", " + moment(ExpireTime).format("hh:mm a")
        );
        formdata.append("comments", Comment);
        formdata.append("customerRef", CustomerRef);
        formdata.append("customerId", ID);
        formdata.append(
          "termsAndConditions",
          TermsConditionSect !== true ? "" : TermsCondition
        );
        formdata.append("sendVia", SMSVia);
        multipleItems === false
          ? formdata.append("invoiceItems", [])
          : formdata.append(
              "invoiceItems",
              JSON.stringify(filteredInvoiceItems)
            );
        files.forEach((file, index) => {
          formdata.append("invoiceFiles", file);
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };

        fetch(
          process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "invoices/new",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result.message === "Successfully Created") {
              setLoader(false);
              swal("Created!", result.message, "success");
              navigate("/invoice-list");
            } else if (
              result.message === "unauthorized" ||
              result.message === "token expire"
            ) {
              swal({
                title: "Session Expired!",
                text: `your session has expired! Kindly login again!`,
                icon: "warning",
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  onLogout();
                } else {
                  onLogout();
                }
              });
            } else {
              swal("Failed!", result.message, "error");
              setLoader(false);
            }
          })
          .catch((error) => console.log("error", error));
      }
    }
  };

  const [CustomerData, setCustomerData] = useState([]);
  const getCustomerData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `customers`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.customers) {
          setCustomerData(result.customers);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getCustomerData();
  }, [accessToken.accessToken]);

  const Users = () => {
    return CustomerData.map((el) => ({
      value: el.mobileNumber,
      label: (
        <div className="flex justify-between items-center">
          {/* {console.log(el)} */}
          <div
            className="flex gap-2 items-center"
            onClick={() => {
              setID(el.customers?.id);
              setName(el.customers?.name);
              setEmail(el.customers?.email);
              setMobileNo(el.customers?.mobileNumber);
            }}
          >
            <i className="bi bi-person-bounding-box text-[#0aa1dd]"></i>
            <p className="font-semibold">{el.name}</p>
          </div>
          <div>{el.mobileNumber}</div>
        </div>
      ),
    }));
  };

  const filterOption = (input, option) => {
    const label = (option?.value ?? "").toLowerCase();
    const inputValue = input.toLowerCase();
    return label.includes(inputValue);
  };

  const [cusName, setCusName] = useState("");
  const [cusEmail, setCusEmail] = useState("");
  const [cusMobile, setCusMobile] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);

  const CreateCustomer = () => {
    setError("");
    if (cusName === "") {
      setError("name is required");
    } else if (cusMobile === "") {
      setError("mobile is required");
    } else if (cusMobile.length > 11 || cusMobile.length < 8) {
      setError("mobile number must be between 8 to 11 digits only.");
    } else if (cusEmail === "") {
      setError("email is required");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cusEmail)) {
      setError("invalid email address");
    } else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        name: cusName,
        email: cusEmail,
        mobileNumber: cusMobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "customers/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Created") {
            getCustomerData();
            setLoading(false);
            setCusName("");
            setCusEmail("");
            setCusMobile("");
          } else if (
            result.message === "unauthorized" ||
            result.message === "token expire"
          ) {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            swal("Denied!", `${result.message}`, "error");
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().endOf("day");
  };

  return (
    <div className="flex flex-col gap-6 animatedView">
      {InputError && (
        <div className="text-sm text-red-500 animatedView">{InputError}</div>
      )}
      {/* First Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4">
        {/* Mobile */}
        <div className="w-full lg:w-1/2">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Mobile Number <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center relative mt-0.5">
            {/* <div className="bg-white border-r rounded-l-lg">
              <select
                id="countries"
                className="bg-white text-gray-900 text-sm rounded-lg block py-[11.9px] px-[15px]"
              >
                <option className="p-1" defaultValue>
                  +965
                </option>
              </select>
            </div> */}
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 34,
                  borderRadius: "6px",
                  colorBorder: "rgba(255,255,255,0)",
                  colorPrimaryHover: "#0aa1dd",
                  lineWidth: 0,
                  fontSize: 12,
                },
              }}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a person"
                className="w-full"
                optionFilterProp="children"
                filterOption={filterOption}
                options={Users()}
                onChange={handleSelectChange}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      {Error && (
                        <div className="text-xs text-red-500">{Error}</div>
                      )}
                      <input
                        type="text"
                        name="name"
                        className={`border rounded-lg p-2 w-full`}
                        value={cusName}
                        onChange={(e) => setCusName(e.target.value)}
                        placeholder="Enter name"
                      />
                      <input
                        type="text"
                        name="mobile"
                        className={`border rounded-lg p-2 w-full`}
                        value={cusMobile}
                        onChange={(e) => setCusMobile(e.target.value)}
                        placeholder="Enter mobile"
                      />
                      <input
                        type="email"
                        name="email"
                        className={`border rounded-lg p-2 w-full`}
                        value={cusEmail}
                        onChange={(e) => setCusEmail(e.target.value)}
                        placeholder="Enter email"
                      />
                      <button
                        className="flex items-center gap-2 bg-black text-white p-1.5 rounded-md"
                        onClick={CreateCustomer}
                      >
                        {Loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <>
                            <span>Add</span>
                            <PlusOutlined />
                          </>
                        )}
                      </button>
                    </Space>
                  </>
                )}
              />
            </ConfigProvider>
            {SearchLoader && (
              <div className="absolute right-4 top-4">
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            )}
          </div>
          <p className="text-xs tracking-wide py-1 text-[#545454]">
            {NotFound === true ? (
              <span className="text-red-500">Not Found</span>
            ) : (
              "please enter full number to get customer data."
            )}
          </p>
        </div>
        {/* Via */}
        <div className="w-full lg:w-1/4">
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Send Invoice via
            </label>
            <select
              id="countries"
              name="smsVia"
              value={SMSVia}
              onChange={(e) => setSMSVia(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-[11px] px-[15px]"
            >
              <option className="p-1" defaultValue value="SMS">
                SMS
              </option>
              <option className="p-1" value="Email">
                Email
              </option>
            </select>
          </div>
        </div>
        {/* Expire */}
        <div className="w-full lg:w-1/4 flex gap-2">
          <div className="flex flex-col w-full">
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Invoice Expire Date <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center gap-2">
              <DatePicker
                onChange={handleExpireChange}
                className="w-full px-3 placeholder:text-black placeholder:font-semibold border-none"
                style={{ padding: 9 }}
                placeholder="dd/mm/yyyy 11:12:00"
                disabledDate={disabledDate}
                defaultValue={DateChnage === 0 ? dayjs(ExpireDate) : ExpireDate}
                format="YYYY-MM-DD"
                allowClear={false}
              />
              <TimePicker
                use12Hours
                className="w-full px-3 placeholder:text-black placeholder:font-semibold border-none"
                style={{ padding: 9 }}
                format="hh:mm a"
                defaultValue={
                  DateChnage === 0
                    ? dayjs(ExpireDate.getTime())
                    : moment().format("hh:mm a")
                }
                onChange={(timeString) =>
                  handleTimeChange(DateChnage, timeString)
                }
                allowClear={false}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Second Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4">
        {/* Name */}
        <div className="w-full lg:w-[48.5%] flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full disabled:bg-white cursor-not-allowed"
            value={Name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your customer name"
            disabled
          />
        </div>
        {/* Invoice Value */}
        {/* <div className="w-full lg:w-1/4 flex flex-col">
         
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Invoice Value <span className="text-red-500">*</span>
          </label>
          <ConfigProvider
            theme={{
              token: {
                controlHeight: 36,
                borderRadius: "6px",
                colorBorder: "rgba(255,255,255,0)",
                colorPrimaryHover: "#0aa1dd",
                lineWidth: 0,
                fontSize: 12,
              },
            }}
          >
            <Select
              // showSearch
              size="large"
              placeholder="Kuwait [KD]"
              className="w-full"
              optionFilterProp="children"
              value={InvoiceValue}
              options={[
                {
                  value: "kwd",
                  label: "KWD",
                },
              ]}
              onChange={(value) => setInvoiceValue(value)}
            />
          </ConfigProvider>
        </div> */}
        {/* Invoice Ref */}
        <div className="w-full lg:w-[50%] flex flex-col">
          {/* Second input taking 25% space */}
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Invoice Ref
          </label>
          <input
            type="text"
            name="invoiceRef"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={InvoiceRef}
            onChange={(e) => setInvoiceRef(e.target.value)}
          />
        </div>
      </div>
      {/* Third Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4 relative">
        {/* Email */}
        <div className="w-full lg:w-1/2 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Email
          </label>
          <input
            type="text"
            name="email"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full disabled:bg-white cursor-not-allowed"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="jhon@example.com"
            disabled
          />
        </div>
        {/* Customer Ref */}
        <div className="w-full lg:w-1/4 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Ref
          </label>
          <input
            type="text"
            name="customerRef"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full disabled:bg-white cursor-not-allowed"
            value={CustomerRef}
            onChange={(e) => setCustomerRef(e.target.value)}
            placeholder=""
            disabled
          />
        </div>
        {/* Comment */}
        <div className="w-full lg:w-1/4 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Comment
          </label>
          <textarea
            name="comment"
            id=""
            cols="30"
            rows="10"
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={Comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        {/* Term and Condition */}
        <div className="w-[74%] absolute -bottom-9 hidden md:hidden lg:block">
          <div>
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Terms & Conditions
            </label>
            {TermsConditionSect && (
              <textarea
                name="termsConditions"
                id=""
                cols="30"
                rows="5"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full animatedView"
                value={TermsCondition}
                onChange={(e) => setTermsCondition(e.target.value)}
              ></textarea>
            )}
          </div>
          <div className="form-check custom-checkbox mb-2">
            <input
              type="checkbox"
              className="form-check-input border-1 border-[#0aa1dd]"
              name="subscriptionGroup"
              onClick={() => setTermsConditionSect(!TermsConditionSect)}
            />
            <label className="form-check-label text-[14px] text-[#545454] font-semibold">
              is Enabled?
            </label>
          </div>
        </div>
      </div>
      {/* fourth for the mobile */}
      <div className="w-full block md:block lg:hidden">
        <div>
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Terms & Conditions
          </label>
          {TermsConditionSect && (
            <textarea
              name="termsConditions"
              id=""
              cols="30"
              rows="4"
              className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full animatedView"
              value={TermsCondition}
              onChange={(e) => setTermsCondition(e.target.value)}
            ></textarea>
          )}
        </div>
        <div className="form-check custom-checkbox mb-2">
          <input
            type="checkbox"
            className="form-check-input border-1 border-[#0aa1dd]"
            name="subscriptionGroup"
            onClick={() => setTermsConditionSect(!TermsConditionSect)}
          />
          <label className="form-check-label text-[14px] text-[#545454] font-semibold">
            is Enabled?
          </label>
        </div>
      </div>
      {/* Quick Invoice Amount */}
      {multipleItems ? (
        false
      ) : (
        <div className="">
          <div className="w-full flex flex-col">
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Invoice Amount <span className="text-red-500">*</span>
            </label>
            <input
              type="text" // Change input type to text
              name="invoiceAmount"
              className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
              value={InvoiceAmount}
              onChange={handleAmountChange}
              placeholder="Enter invoice amount"
              inputMode="decimal" // Helps on mobile devices to show number pad with decimal
            />
          </div>
          {AmountError && (
            <div className="text-xs font-semibold text-red-600 m-1 animatedView">
              {AmountError}
            </div>
          )}
        </div>
      )}
      {/* Upload */}
      <div>
        <label
          className="text-[#545454] text-[14px] font-semibold"
          htmlFor="file_input"
        >
          Upload file
        </label>
        <input
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 p-3"
          id="file_input"
          type="file"
          onChange={handleImageChange}
        />
      </div>
      <div className="flex justify-between items-center text-lg font-semibold">
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label="Add Items"
          style={{ cursor: "pointer" }}
          onChange={() => setMultipleItems(!multipleItems)}
        />
        {multipleItems && (
          <div className="flex items-center gap-2 animatedView">
            <p className="text-[#000] text-lg font-semibold">Invoice amount</p>
            <p className="bg-[#fff] font-semibold p-1 md:p-2 px-4 rounded-lg text-sm lg:text-base">
              {totalSubtotal}
            </p>
            /- {InvoiceValue}
          </div>
        )}
      </div>
      {/* Add Item */}
      {multipleItems && (
        <div className="animatedView">
          {/* Button */}
          <button
            className="text-[#545454] font-semibold text-base flex items-center gap-2"
            onClick={handleAddItem}
          >
            Add Item{" "}
            <span>
              <i className="bi bi-plus-circle text-lg"></i>
            </span>
          </button>
          <div className="border-t border-dashed w-full border-[#545454]" />
          {/* Items */}
          {invoiceItems.map((item, index) => (
            <div
              className="flex flex-col md:flex-col lg:flex-row gap-4 mt-3 animatedView items-center"
              key={index}
            >
              <div className="w-full lg:w-1/2 flex flex-col">
                <label className="text-[#545454] text-[14px] font-semibold">
                  Details:
                </label>
                <input
                  type="text"
                  name="details"
                  className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
                  placeholder="Enter details"
                  value={item.details}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="w-full lg:w-1/4 flex flex-col">
                <label className="text-[#545454] text-[14px] font-semibold">
                  Amount:
                </label>
                <input
                  type="number"
                  name="amount"
                  className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
                  value={item.amount}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <div className="w-full lg:w-1/4 flex flex-col">
                <label className="text-[#545454] text-[14px] font-semibold">
                  Quantity:
                </label>
                <input
                  type="number"
                  name="quantity"
                  className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
                  value={item.quantity}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              <button className="mt-4" onClick={() => handleDeleteItem(index)}>
                <div className="flex justify-center items-center w-11 h-11 bg-red-500 rounded-full">
                  <RxCross1 className="text-white" size={20} />
                </div>
                <span>
                  <i className="bi bi-x-lg text-lg"></i>
                </span>
              </button>
            </div>
          ))}
        </div>
      )}
      {/* button */}
      <div className="flex justify-center mb-5">
        <button
          className="text-white bg-[#0aa1dd] font-semibold text-[20px] py-2 px-4 rounded-lg"
          onClick={handleCreateInvoice}
        >
          {Loader === true ? (
            <Spinner animation="border" variant="light" />
          ) : (
            "CREATE INVOICE"
          )}
        </button>
      </div>
    </div>
  );
};

export default CreateInvoice;
