import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import CustomerInfo from "./CustomerInfo";
import OtherInfo from "./OtherInfo";
import MoreOptions from "./MoreOptions";
import PaymentDetails from "./PaymentDetails";
import DetailTabs from "./DetailTabs";
import InvoiceLogo from "../../../../../images/svg/invoice.svg";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../../store/actions/AuthActions";

const Card = ({ children, Head, Icons }) => {
  return (
    <div className="mb-5">
      <div className="flex justify-between items-center mx-2">
        <h1 className="font-semibold text-[16px] text-[#0aa1dd]">{Head}</h1>
        <img src={Icons} alt="" />
      </div>
      <hr className="my-3" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 my-4">
        {children}
      </div>
    </div>
  );
};

const Label = ({ label }) => {
  return (
    <label htmlFor="" className="text-sm font-semibold">
      {label}
    </label>
  );
};

const Desc = ({ desc }) => {
  return <p className="text-sm text-gray-400 font-semibold">{desc}</p>;
};

const InvoiceDetails = () => {
  let { id } = useParams();
  let { ref } = useParams();
  let [searchParams] = useSearchParams();

  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let navigate = useNavigate();
  const [Data, setData] = useState();
  const [Loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }
  const getDataOfInvoice = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `invoices/details/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.invoice) {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          setData(result.invoice);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.error(error));
    setTimeout(() => {
      setLoading(false);
    }, 8000);
  };

  useEffect(() => {
    // if (securedName !== ref) {
    //   navigate(-1);
    // } else {
    getDataOfInvoice();
    // }
  }, []);

  const [ResendLoad, setResendLoad] = useState(false);
  const handleResendLink = () => {
    setResendLoad(true);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `invoices/send/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.message === "Sent successfully") {
          swal("Sent", `Invoice has been sent to ${ref}`, "success");
          setResendLoad(false);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        } else {
          setResendLoad(false);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {Loading ? (
        <div className="flex justify-center items-center h-[95vh] bg-white -m-10">
          <Spinner animation="border" variant="primary" className="mb-20" />
        </div>
      ) : (
        <div className="flex flex-col gap-6 animatedView">
          {/* {console.log(Data)} */}
          {/* Head */}
          <div className="bg-white p-2 pt-3 rounded px-5 flex justify-between items-center">
            <h1 className="font-semibold text-xl text-gray-900">
              Invoice Details
            </h1>
            <div className="flex items-center gap-3">
              <button
                className="p-2 xl:p-3 px-2 lg:px-3 bg-[#0aa1dd] text-white rounded-md"
                onClick={handleResendLink}
              >
                {ResendLoad ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  "Resend Invoice Link"
                )}
              </button>
              <img src={InvoiceLogo} alt="icon" />
            </div>
          </div>
          {/* Basic */}
          <div className="bg-white p-4 rounded">
            <BasicInfo Card={Card} Label={Label} Desc={Desc} data={Data} />
            <div>
              <div className="my-5">
                <Label label="Comments" />
                {/* <Desc
                  desc={
                    Data?.comments === "" ? (
                      <p className="text-red-500 font-semibold">
                        No comments recieved!
                      </p>
                    ) : (
                      Data?.comments?.split("-")
                      .map((item) => item.trim())
                      .join("\n")
                    )
                  }
                /> */}
                <textarea
                  name=""
                  value={
                    Data?.comments === ""
                      ? "No comments recieved!"
                      : Data?.comments
                  }
                  id=""
                  rows={10}
                  className="disabled:bg-white text-sm font-semibold text-gray-400 text-justify w-full tracking-wide"
                  disabled
                  style={{ resize: "none" }}
                ></textarea>
              </div>
            </div>
            <CustomerInfo
              Card={Card}
              Label={Label}
              Desc={Desc}
              data={Data?.Customer}
              customerRef={Data?.customerRef}
            />
            <OtherInfo Card={Card} Label={Label} Desc={Desc} data={Data} />
            <MoreOptions Card={Card} Label={Label} Desc={Desc} data={Data} />
            <PaymentDetails Card={Card} Label={Label} Desc={Desc} data={Data} />
            <div className="flex justify-end">
              <button
                className="bg-[#0aa1dd] p-2 px-5 text-white font-semibold tracking-wide rounded"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
            <DetailTabs Card={Card} data={Data} />
          </div>
          <div />
        </div>
      )}
    </>
  );
};

export default InvoiceDetails;
