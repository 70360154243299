import { useState } from "react";
import { createContext, useContext, useEffect } from "react";

let PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  const [Permissions, setPermissions] = useState("");

  const getPermission = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `/users/permission/${accessToken?.user?.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setPermissions(JSON.parse(result.user?.permissions));
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    getPermission();

    const interval = setInterval(getPermission, 10000); // Fetch data every 30 seconds

    return () => clearInterval(interval);
  }, [accessToken.accessToken, accessToken.client.id]);

  return (
    <PermissionContext.Provider value={{ Permissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionContext);
