import React from "react";
import info from "../../../../../images/svg/info.svg";

const OtherInfo = ({ Card, Label, Desc, data }) => {
  return (
    <Card Head="Other Info" Icons={info}>
      {/* <div>
        <Label label="Invoice Option" />
        <Desc desc="-" />
      </div> */}
      <div>
        <Label label="Condition apply" />
        <Desc desc={data?.termsAndConditions === "" ? "No" : "Yes"} />
      </div>
      {/* <div>
        <Label label="" />
        <Desc desc="" />
      </div> */}
      <div>
        <Label label="Notes" />
        <textarea
          name=""
          value={data?.termsAndConditions === "" ? "-" : data?.termsAndConditions}
          id=""
          rows={10}
          className="disabled:bg-white text-sm font-semibold text-gray-400 text-justify w-full tracking-wide"
          disabled
          style={{ resize: "none" }}
        ></textarea>
      </div>
    </Card>
  );
};

export default OtherInfo;
