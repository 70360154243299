import React from "react";
import option from "../../../../../images/svg/option.svg";

const MoreOptions = ({ Card, Label, Desc, data }) => {
  return (
    <Card Head="More Options" Icons={option}>
      <div>
        <Label label="Send Invoice Option" />
        <Desc desc={data?.sentVia} />
      </div>
      <div>
        <Label label="View" />
        <Desc desc={data?.InvoiceViews?.length} />
      </div>
      <div>
        <Label label="Sent SMS Counter" />
        <Desc desc="-" />
      </div>
      <div>
        <Label label="Sent Email Counter" />
        <Desc desc={data?.InvoiceEmails?.length} />
      </div>
      {/* <div>
        <Label label="Remind after" />
        <Desc desc="-" />
      </div> */}
    </Card>
  );
};

export default MoreOptions;
